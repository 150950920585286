<template>
    <div class="msg-list-container">
        <div class="msg-list-tools">
            <el-form class="battery-form" :inline="true" :model="formData">
                <el-form-item label="电池ID">
                    <el-input v-model="formData.batteryCode" placeholder="电池ID" size="small" class="area-form-item" style="width: 180px;">
                    </el-input>
                </el-form-item>
                <el-form-item label="告警类型">
                    <el-select v-model="formData.msgType" placeholder="告警类型" size="small" class="area-form-item">
                        <el-option label="全部" :value="''"></el-option>
                        <el-option label="报警" :value="2"></el-option>
                        <el-option label="预警" :value="1"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="告警时间">
                    <el-date-picker
                        v-model="formData.msgTime"
                        size="small"
                        type="datetimerange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item style="float: right; marginRight: 0px;">
                    <el-button type="primary" @click="getData(1)">搜索</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="msg-list-content">
            <el-table 
                height="100%"
                :data="list"
            >
                <el-table-column label="告警类别" prop="alarmCodeName"></el-table-column>
                <el-table-column label="电池ID" prop="batteryCode"></el-table-column>
                <el-table-column label="电池型号" prop="batteryType"></el-table-column>
                <el-table-column label="故障内容" prop="alarmText"></el-table-column>
                <el-table-column label="故障等级" prop="alarmLevel"></el-table-column>
                <el-table-column label="发生时间" prop="createTime"></el-table-column>
                <el-table-column label="归属站点" prop="siteName"></el-table-column>
            </el-table>
        </div>
        <div class="msg-page-container">
            <div class="msg-page-content">
                <el-pagination
                    background
                    :current-page="page"
                    :page-sizes="[10, 20, 30, 40]"
                    :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                >
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import './Index.less';
import axios from '@/utils/axios';
import moment from 'moment';
export default {
    name: 'MsgList',
    data() {
        return {
            formData: {
                msgType: '',
                msgTime: '',
                batteryCode: ''
            },
            page: 1,
            pageSize: 10,
            list: [],
            total: 0
        }
    },
    methods: {
        getData(_page) {
            const {
                page,
                pageSize
            } = this;
            const {
                msgType,
                msgTime,
                batteryCode
            } = this.formData;
            let startTimeStr = '';
            let endTimeStr = '';
            if (msgTime) {
                const [startTime, endTime] = msgTime;
                startTimeStr = moment(startTime).format('YYYY-MM-DD HH:mm:ss');
                endTimeStr = moment(endTime).format('YYYY-MM-DD HH:mm:ss');
            }
            axios.get(`/api/batteryAlarmHistory/pageList/${_page ? _page : page}/${pageSize}`, {
                startTime: startTimeStr,
                endTime: endTimeStr,
                type: msgType,
                batteryCode
            }).then(rs => {
                const { records, total, page } = rs;
                this.list = records;
                this.total = total;
                this.page = page;
            }).catch(err => {
                this.$message.error(err);
            });
        },
        handleSizeChange(val) {
            this.pageSize = val;
            this.getData();
        },
        handleCurrentChange(val) {
            this.page = val;
            this.getData();
        },
    },
    mounted() {
        const { type } = this.$route.params;
        if (type && type === 'alarm') {
            this.formData.msgType = 2;
        } else if (type && type === 'preAlarm') {
            this.formData.msgType = 1;
        }
        this.getData();
    }
}
</script>